@import './ping-constants.css';

/*
    This file is for the styling for container search.
*/
#left_panel .related_booking_list h3 {
    font-size: 1em;
    font-weight: normal;
    color: var(--light-grey-text);
    margin: 0.5em;
    text-align: center;
    padding-bottom: 0.5em;
    border-bottom: var(--light-grey-border);
}

/* The related booking list at the left panel */
#left_panel .related_booking_list .booking_ref { margin-bottom: 0.5em; }
#left_panel .related_booking_list .booking_ref span { font-weight: bold; }

/* The hover effect for the rows */
#left_panel .related_booking_row { cursor: pointer; }
#left_panel .related_booking_row label { cursor: pointer; }
#left_panel .related_booking_row:hover {
    background: #1C1D2105;
    border-color: #8996AF33;
}

/* Special styling for country */
.related_booking_row .field label {
    color: var(--light-grey-text);
    display: block;
    font-size: 0.9em;
}
.related_booking_row .field img.flag { 
    border: var(--light-grey-border);
    border-radius: 100%;
    width: 2em;
    height: 2em;
    object-fit: cover;
}
.related_booking_row .field span.country {
    display: flex;
    align-items: center;
}
.related_booking_row .field span.country span.portcode { 
    font-size: 0.8em;
    margin-left: 1em;
    padding: 0.5em;
    border: var(--light-grey-border);
    box-shadow: var(--light-grey-border-shadow);
    border-radius: 1em;
}
.related_booking_row .field.pol_pod_datetime { white-space: nowrap; overflow: visible; }
.related_booking_row .field span.on { 
    font-weight: normal;
    color: var(--light-grey-text); 
}

/* The arrow in-between POL and POD */
.related_booking_row .pol { padding-right: 1em; }
.related_booking_row .pod { padding-left: 1em; position: relative; }
.related_booking_row .pod .arrow {
    position: absolute;
    left: -1.5em;
    top: 1em;
    width: 1.5em;
    height: 1.5em;
}

/* The booking created date */
.related_booking_row .booking_created_date { margin-top: 1em; }
.related_booking_row .booking_created_date svg { margin-right: 0.5em; }

/* light grey color */
.related_booking_row .booking_created_date svg path { fill: var(--light-grey-text); }
.related_booking_row .booking_created_date span { color: var(--light-grey-text); }
