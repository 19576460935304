@import './ping-constants.css';

/*
    This file is for the styling shared among:
    booking list, favourite list, and search history list.
*/

.search_list_row {
    border: 0.1em solid #F1F1F2;
    border-radius: 1em;
    padding: 0.5em;
    cursor: pointer;
    margin-bottom: 0.5em;
    margin-right: 0.5em; 
}
.search_list_row label { cursor: pointer; }
.search_list_row:hover {
    background-color: #5E81F40A;
}
.search_list_row:active {
    background-color: #5E81F41A;
}

.search_list_row .search_type span {
    padding: 0 0.5em;
    text-align: center;
    background: white;
    color: #5E81F4;
    border: var(--light-grey-border);
    box-shadow: var(--light-grey-border-shadow);
    border-radius: 1em;
}

.search_list_row .field { height: 3em; }
.search_list_row .field label {
    color: var(--light-grey-text);
    display: block;
    font-size: 0.9em;
    margin-bottom: 0;
}
.search_list_row .field span { 
    font-weight: bold; 
    font-size: 1.1em;
}
