@import './ping-constants.css';

/*
    Styling here is designed for 1920 res.
    For responsiveness, check the resposive css file.

    This file is for the base frame for ping search page.
*/
/* -------------------------------------------------------------------------- */
/* General */
/* -------------------------------------------------------------------------- */
#ping_search_page .MuiPaper-rounded { border-radius: 0.5em; }

/* Change the default style for the material-ui switches */
#ping_search_page .MuiSwitch-root { padding: 9px; }
#ping_search_page .MuiSwitch-track { background: #EEEEEE; border-radius: 9px; }
#ping_search_page .Mui-checked + .MuiSwitch-track { background: #5E81F4; }
#ping_search_page .MuiSwitch-thumb { background: white; }

/* The loading spinner */
#ping_search_page .MuiPaper-root div.loading { 
    width: 100%; height: 100%;
    padding: 1em;
}
#ping_search_page .MuiPaper-root div.loading label {
    margin-left: 1em;
    font-size: 2em;
}

/* -------------------------------------------------------------------------- */
/* Top Panel */
/* -------------------------------------------------------------------------- */
#top_panel > .MuiPaper-root { width: 100%; padding: 1em; }

/* The links */
#top_panel_links .top_panel_link { 
    color: var(--blue-link-color);
    text-decoration: none;
    margin-right: 1em; 
}
#top_panel_links .top_panel_link span { margin-left: 0.2em; }

/* Style the dropdown menu item */
#search_by { width: 10em; }
#search_by .MuiInputBase-root { border-radius: 0.5em; }
#search_by .MuiSelect-root { border-radius: 0.5em; }
#search_by .MuiInputBase-root.Mui-focused { background: #5E81F419; }

.search-by-item { background: #FAFBFC; }
.search-by-item span.code { 
    display: inline-block;
    width: 5em;
    padding: 0 1em;
    text-align: center;
    background: white;
    border: var(--light-grey-border);
    border-radius: 1em;
    box-shadow: var(--light-grey-border-shadow);
    color: #5E81F4;
    margin-right: 0.5em;
}

/* The search form */
#search_input {
    border: var(--light-grey-border);
    border-radius: 0.5em;
    padding: 0.1em;
    background: #5E81F405;
}

/* Positioning within the search form */
#search_form { display: inline-flex; }
#search_form button { margin-left: 2em; width: 10em; }

/* Positioning within the search input */
#search_form #search_input { flex-grow: 1; display: inline-flex; align-items: center; }
#search_form #search_input svg#search_icon {
    width: 1.2em; height: 1.2em;
    margin: 0.5em;    
}
#search_form #search_input input#search_value {
    font-size: 1.2em;
    border: none;
    outline: none;
    background: transparent;
    flex-grow: 1;
    height: 50%;
    line-height: 50%;
}

/* The AutoComplete for the Vessel Search */
#search_form #search_input .MuiAutocomplete-root.search_value > .MuiFormControl-root > .MuiAutocomplete-inputRoot {
    padding-right: 2em;
}

/* Remove the down arrow from the autocomplete field for vessel search. */
#search_form #search_input .MuiAutocomplete-root.search_value .MuiAutocomplete-popupIndicator {
    display: none;
}

/* The loading spinner for the search button */
#search_form button .MuiCircularProgress-root { position: absolute; }

/* -------------------------------------------------------------------------- */
/* Bottom panel */
/* -------------------------------------------------------------------------- */
#bottom_panel > .MuiPaper-root { width: 100%; padding: 1.5em; }

/* The material-ui's flex cannot set the perfect proportion...
No choice, but to overwrite it. This is for the 1024 up to 1920, grid container. */
@media (min-width: 960px) and (max-width: 1919px) {
    #bottom_panel #left_panel { max-width: 30%; flex-basis: 30%; }
    #bottom_panel #right_panel { max-width: 70%; flex-basis: 70%; }
}

/* -------------------------------------------------------------------------- */
/* Left panel */
/* -------------------------------------------------------------------------- */
#left_panel { padding-right: 1em; }
#left_panel > .MuiPaper-root { width: 100%; }

/* For the placeholder left panel */
#left_panel #placeholder_left_panel { padding: 3em; }

#left_panel #placeholder_left_panel > div { margin-bottom: 1em; }
#left_panel #placeholder_left_panel h2 { 
    text-align: center;
    font-size: 1.8em;
}
#left_panel #placeholder_left_panel span {
    display: block;
    margin-top: 1em;
    text-align: center;
    color: var(--light-grey-text);
}
#left_panel #placeholder_left_panel img { width: 100%; }

/* For search result left panels */
#left_panel > .MuiPaper-root > .MuiContainer-root { padding: 0; }

#left_panel .bookmark_bar {
    position: relative;
    padding: 1em;
    background: #5E81F433;
    border-radius: 0.5em;
}
#left_panel .bookmark_bar span {
    display: block;
    padding-right: 2em;
    font-weight: bold;
}
#left_panel .bookmark_bar div.star {
    position: absolute; top: 0.25em; right: 0.25em;
    width: 2.5em; height: 2.5em;
    padding: 0.5em;
    border-radius: 0.5em;
    cursor: pointer;
}
#left_panel .bookmark_bar div.star svg { width: 100%; height: 100%; }

/* The hover effect for the stars */
#left_panel .bookmark_bar .star:hover { background: #F5F5FA; }
#left_panel .bookmark_bar .solid_star svg path { fill: #F4BE5E; }

/* The info header (with icon and the reference number only) */
#left_panel .info_header {
    padding: 1.5em;
    text-align: center;
}
#left_panel .info_header .header_icon {
    display: inline-block;
    width: 4em; height: 4em;
    padding: 0.8em;
    background: #1C1D2102;
    border: 0.1em solid #0000000F;
    border-radius: 100%;
    margin-bottom: 0.5em;
}
#left_panel .info_header .header_icon svg { width: 100%; height: 100%; }
#left_panel .info_header .header_icon svg path { fill: #707070; }

/* left panel field row, eg: freight booking ID. */
#left_panel .info_row { margin: 1em; }
#left_panel .info_row label {
    display: block;
    color: var(--light-grey-text);
}

#left_panel .info_row span {
    display: block;
    font-size: 1.1em;    
}

#left_panel .info_row ul { margin-left: 2em; margin-right: 2em; word-wrap: break-word;}
#left_panel .info_row li { margin-top: 0.3em }

#left_panel .info_group {
    border: var(--light-grey-border);
    border-radius: 1em;
    margin: 1em;
    background: #5E81F405;
}

/* any left panel row, eg: related booking. */
#left_panel .left_panel_row {
    margin: 0.5em;
    padding: 0.5em;
    border: var(--light-grey-border);
    border-radius: 0.5em;
}

#left_panel .left_panel_row:hover {
    margin: 0.5em;
    padding: 0.5em;
    border: var(--light-grey-border);
    border-radius: 0.5em;
}

/* Placeholder - error */
#left_panel div.error {
    display: flex; flex-direction: column; align-items: center;
    padding: 3em;
}
#left_panel div.error img { width: 10em; height: 10em; }
#left_panel div.error label {
    font-size: 1.5em;
    font-weight: bold;
    margin: 1em 0;
}
#left_panel div.error span {
    display: block;
    text-align: center;
    color: #8181A5;
}

/* Placeholder - No booking data */
#left_panel div.no_booking_data {
    display: flex; flex-direction: column; align-items: center;
}
#left_panel div.no_booking_data img { 
    width: 10em; height: 10em; 
    margin-top: 5em;
}
#left_panel div.no_booking_data label {
    font-size: 1.5em;
    font-weight: bold;
    margin-top: 1em;
}

/* -------------------------------------------------------------------------- */
/* Right panel */
/* -------------------------------------------------------------------------- */
#right_panel .MuiPaper-root { width: 100%; padding: 1em; }

#right_panel #right_panel_title h3 { font-size: 1.5em; }

/* The Collapse All / Expand All control label */
#collapse_expand_control { text-align: right; }
#collapse_expand_control .MuiFormControlLabel-label { font-size: 1em; }
#collapse_expand_control [data-is-expanded="true"] span.collapse { color: var(--light-grey-text); }
#collapse_expand_control [data-is-expanded="false"] span.expand { color: var(--light-grey-text); }

#right_panel #result_count span {
    color: var(--light-grey-text);
}
#right_panel #sort_form { text-align: right; }
#right_panel #sort_form span { color: #8996AF; }
#right_panel #sort_form #sort_field { 
    width: 8em; 
    text-align: left;
    margin-left: 0.5em;
    font-weight: bold;
}

#right_panel_result { 
    margin-top: 1em; 
    min-height: 50vh;
    padding-left: 0;
    padding-right: 0;
}

/* Placeholder - Error */
#right_panel div.error {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5em;
}
#right_panel div.error img { width: 20em; height: 20em; }
#right_panel div.error label { 
    font-size: 2em;
    font-weight: bold;
    margin: 1em 0;
}
#right_panel div.error span { color: #8181A5; }

/* Placeholder - No data */
#right_panel div.no_data {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    position: relative;
    padding: 5em;
}
#right_panel div.no_data h3 { 
    font-size: 1.5em;
    position: absolute; top: 1em; left: 1em; 
}
#right_panel div.no_data img { width: 35%; }
#right_panel div.no_data label {
    font-size: 2em;
    font-weight: bold;
    margin-top: 1em;
}

/* Loading spinner for infinite scroll */
#right_panel #right_panel_result .load_more {
    width: 100%;
    margin: 1em 0;
    text-align: center;
}
