@import './ping-constants.css';

/*
    This file is for the styling for booking list.
*/

.booking_row .carrier_ref span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* Special styling for country */
.booking_row .field img.flag { 
    border: var(--light-grey-border);
    border-radius: 100%;
    width: 2em;
    height: 2em;
    object-fit: cover;
}
.booking_row .field span.country {
    display: flex;
    align-items: center;
}
.booking_row .field span.country span { margin-left: 0.5em; }
.booking_row .field span.country span.portname { 
    font-size: 1em;
    width: 7em;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.booking_row .field span.country span.portcode { 
    font-size: 0.8em;
    width: 5em;
    margin-left: 1em;
    padding: 0.5em;
    text-align: center;
    border: var(--light-grey-border);
    box-shadow: var(--light-grey-border-shadow);
    border-radius: 1em;
}
.booking_row .field span.on { 
    font-weight: normal;
    color: var(--light-grey-text); 
}

/* The arrow in-between POL and POD */
.booking_row .pol { padding-right: 1em; }
.booking_row .pod { padding-left: 1em; position: relative; }
.booking_row .pod .arrow {
    position: absolute;
    left: -0.75em;
    top: 1em;
    width: 1.5em;
    height: 1.5em;
}

.booking_row .created_date { padding-left: 1em; }