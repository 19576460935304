/*
    This file is to adjust the styles for different screen size.
*/

/* For 1600 res and below */
@media screen and (max-width: 1900px) {
    #ping_search_page { font-size: 14px; }
}

/* For 1280 and 1366 res and below*/
@media screen and (max-width: 1580px) {
    #bottom_panel { font-size: 11px; }
}

/* For 1024 res and below */
@media screen and (max-width: 1200px) {
    .ping_booking_list #right_panel_result { font-size: 10px; }
}

/* For 768 res and below */
@media screen and (max-width: 900px) {
    #left_panel { padding-right: 0; margin-bottom: 1em; }
    #left_panel #placeholder_image { display: none; }
}