@import './ping-constants.css';

/*
    This file is for the styling for favourite list.
*/

/* Styling for the star. */
.favourite_row .star { position: relative; }
.favourite_row .star > div {
    width: 2em;
    height: 2em;
    position: absolute;
}
.favourite_row .star svg { width: 100%; height: 100%; }

.favourite_row .solid_star svg path { fill: #F4BE5E; }

/* The hover effect for the stars */
.favourite_row .solid_star { z-index: 200; }
.favourite_row .outline_star { z-index: 100; }
.favourite_row .solid_star:hover svg { display: none; }

/* The fade effect for delete record */
.favourite_row { 
    height: 4em;
    transition: all 0.5s ease-in-out;
}
.favourite_row[data-deleted="true"] { 
    opacity: 0; overflow: hidden;
    height: 0; padding: 0; margin: 0; border: 0;
}
