@import './ping-constants.css';

/*
    This file is for the styling shared among:
    booking milestone result, container milestone result.
*/

.milestone_row { 
    min-height: 4em;
}
.milestone_date { 
    position: relative;
    padding: 1em;
    text-align: right;
}
.milestone_date label { width: 100%; margin-right: 1.5em; }
.milestone_date label span { display: block; color: #8996AF; }
.milestone_date label .date { font-weight: bold; font-size: 1.2em; }
.milestone_date label .time { font-weight: bold; font-size: 0.9em; }

/* The connecting line and dot */
.milestone_date .line {
    width: 1.5em;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    background: #EEEEEE;
    border-left: 0.9em solid white;
    border-right: 0.9em solid white;
}

.milestone_date .dot {
    position: absolute;
    top: 1.5em;
    right: -0.6em;
    width: 1.2em;
    height: 1.2em;
    border: 0.2em solid #1C1D2154;
    border-radius: 100%;
    background: white;
}

/* Special styling for first and last row */
.milestone_row:first-child .milestone_date label .date { color: black; }
.milestone_row:first-child .milestone_date .line { top: 1.5em; }
.milestone_row:first-child .milestone_date .dot { top: 0; border-color: #5E81F4; }
.milestone_row:last-child .milestone_date .line { height: 1.5em; }

/* Milestone details */
.milestone_row .milestone_event { 
    padding: 0.5em; 
    margin-bottom: 2em;
}
.milestone_row .milestone_event > .MuiContainer-root {
    border: 0.1em solid #E4EBF6A2;
    border-radius: 1em;
    background: white; 
    padding: 0;
}

/* Milestone header (event name, locode, and arrow) */
.milestone_row .milestone_event .milestone_event_header {
    background: #8996AF10;
    border-radius: 1em;
    height: 4em;
}
.milestone_row .milestone_event .milestone_event_header .event_locode_box {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
}

.milestone_row .milestone_event .milestone_event_header .event_name {
    font-size: 1.1em;
    font-weight: bold;
    padding: 0 1em;
    text-transform: uppercase;
}

.milestone_row .milestone_event .milestone_event_header .event_locode { text-align: right; }
.milestone_row .milestone_event .milestone_event_header .event_locode > div {
    display: inline-flex;
    align-items: center;
    padding: 0.5em;
    background: white;
    border: 0.1em solid #E8E8E8;
    border-radius: 1em;
}
.milestone_row .milestone_event .milestone_event_header .event_locode .flFag{
    margin-right: 0.4em;
    width: 1.5em;
}
.milestone_row .milestone_event .milestone_event_header .flag {
    width: 1.5em;
    height: 1.5em;
    object-fit: cover;
    border-radius: 100%;
    margin-right: 0.5em;
}

.milestone_row .milestone_event .milestone_event_header .arrow {
    text-align: right;
    padding-right: 1em;
    padding-left: 1em;
}

.milestone_row .milestone_event .milestone_event_header .arrow > div {
    display: inline-block;
    background: white;
    padding: 0.4em;
    border: 0.1em solid #ECECF2;
    border-radius: 0.5em;
    cursor: pointer;
}

.milestone_row .milestone_event .milestone_event_header .arrow svg {
    display: block;
    width: 1em;
    height: 1em;
}
.milestone_row .milestone_event .milestone_event_header .arrow svg path { fill: #5E81F4; }

/* Milestone event details header */
.milestone_row .milestone_event_detail_header { height: 4em; }
.milestone_row .milestone_event_detail_header .MuiGrid-item {
    padding: 0 1em;
    font-weight: bold;
    text-transform: uppercase;
}
.milestone_row .milestone_event_detail_header .MuiGrid-item label {
    margin-bottom: 0;
}

/* Milestone event details row */
.milestone_row .milestone_event_detail_row { padding: 0.5em; }
.milestone_row .milestone_event_detail_row div:not(:first-child) { margin-top: 0.5em; }

.milestone_row .milestone_event_detail_row > .MuiGrid-container {
    background: #FAFBFC;
    border: 0.1em solid #E4EBF6A2;
    border-radius: 1em;
    height: 4em;
}

.milestone_row .milestone_event_detail_row > .MuiGrid-container > .MuiGrid-item { padding: 0 1em; word-wrap: break-word;}

.milestone_row .milestone_event_detail_row .icon {
    display: inline-flex;
    background: white;
    border: 1px solid #E4EBF6A2;
    border-radius: 100%;
    padding: 0.5em;
    margin-right: 0.5em;
}
.milestone_row .milestone_event_detail_row .icon svg {
    width: 1.5em;
    height: 1.5em;
}
.milestone_row .milestone_event_detail_row span { color: #8996AF; }

/* Customize the different types of icons */
.milestone_row .milestone_event_detail_row .icon.vessel_name { background: #8996AF; }
.milestone_row .milestone_event_detail_row .icon.icon.vessel_name svg path { fill: white; }

/* Collapse and Expand */
.milestone_row .milestone_event_detail_wrapper {
    overflow: hidden;
    transition: max-height 0.5s ease, opacity 0.5s ease;
}

.milestone_row[data-is_collapsed="true"] .milestone_event { flex-direction: column; }
.milestone_row[data-is_collapsed="true"] .milestone_event_detail_wrapper { max-height: 0; opacity: 0; }
.milestone_row[data-is_collapsed="false"] .milestone_event_detail_wrapper { max-height: 10em; opacity: 1; }

.milestone_row div.arrow svg { transition: transform 0.2s linear; }
.milestone_row[data-is_collapsed="false"] div.arrow svg { transform: rotate(180deg); }
