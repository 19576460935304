@import './ping-constants.css';

/*
    This file is for the styling for booking search.
*/

/* left panel */
#left_panel .info_header .company_logo {
    max-width: 100%; max-height: 4em;
    margin-bottom: 1em;
}

/* The port section */
#left_panel .port_info {
    margin: 0 1em;
    padding: 1em 0;
    border-bottom: var(--light-grey-border);
    border-top: var(--light-grey-border);
}
#left_panel .port_info .pol { margin-bottom: 2em; }

#left_panel .port_info .icon { 
    text-align: center; 

    /* for the line */
    position: relative;
}

#left_panel .port_info .icon .icon_border {
    display: inline-block;
    padding: 0.3em;
    border: var(--dark-grey-border);
    border-radius: 100%;
    width: 2.5em; height: 2.5em;

    /* for the line */
    position: relative;
    z-index: 200;
    background: white;
}
#left_panel .port_info .icon img { width: 100%; height: 100%; }
#left_panel .port_info .icon .line {
    position: absolute; 
    top: 0; bottom: -2em; left: 0;
    z-index: 100;
    width: 50%;
    border-right: var(--light-grey-border);
}

#left_panel .port_info label.port { color: var(--light-grey-text); }
#left_panel .port_info label.port::after {
    content: '|';
    color: black;
    margin: 0 1em;
}

#left_panel .port_info label.on { color: var(--light-grey-text); }
#left_panel .port_info span.port_datetime { margin-left: 0.5em; }

#left_panel .port_info .country_flag { 
    border: var(--light-grey-border);
    border-radius: 100%; width: 2em; height: 2em; 
}
#left_panel .port_info .port_name { margin: 0 0.5em; }
#left_panel .port_info .port_code {
    font-size: 0.9em;
    border: var(--light-grey-border);
    border-radius: 1em;
    padding: 0.2em 0.5em;
    background: #FAFBFC;
}

/* equipment details */
#left_panel .equipment_details ul {
    margin-left: 0;
    list-style: none;
}
#left_panel .equipment_details li { 
    margin: 0.5em 0; 
    display: flex; align-items: center;
}
#left_panel .equipment_details span { display: inline-block; }
#left_panel .equipment_details span.code { 
    border: var(--light-grey-border);
    padding: 0.2em 0.5em;
    border-radius: 1em;
    background: white;
    margin-right: 0.5em;
}

/* container details */
#left_panel .container_details ul { 
    margin-left: 0;
    list-style: none;
}
#left_panel .container_details li { 
    padding: 0.5em 0;
    border-bottom: var(--light-grey-border);
    display: flex; align-items: center;

    /* For the copy icon */
    position: relative;
}
#left_panel .container_details li:last-child { border-bottom: none; }
#left_panel .container_details span { display: inline-block; }
#left_panel .container_details svg { color: var(--light-grey-text); }
#left_panel .container_details .container_icon { margin-right: 0.5em; }
#left_panel .container_details .copy_icon { 
    position: absolute; right: 1em;
    padding: 0.25em;
    border: var(--light-grey-border);
    border-radius: 0.5em;
    background: white;
    width: 1.75em; height: 1.75em;
    cursor: pointer;
}
#left_panel .container_details .copy_icon svg { width: 1em; height: 1em; }
#left_panel .container_details .copy_icon:hover {
    background: #eeeeee;
    border-color: #dddddd;
}

